import "./styles/main.scss";
import "plyr/dist/plyr.css";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";
import "tippy.js/animations/shift-toward.css";
import "tippy.js/animations/shift-away.css";
import "highlight.js/styles/default.css";
import Vue from "vue";
import amplitude, { AmplitudeClient } from "amplitude-js";
import { AxiosInstance } from "axios";
import { RestCalls } from "@coveo/levelup-protocol";
import { RestUserDetails } from "@coveo/levelup-protocol";
import { initializeHeadlessEngine } from "./headless";
import amplitudeAnalytics from "@/amplitude-analytics";
import { EventWatcher } from "@/event-bus";
import { RestInitConfig } from "@coveo/levelup-protocol";
import toasts from "@/toasts";
import navigation from "@/navigation";
import oneTrust from "@/one-trust";
import assert from "assert";
import { AuthoringCalls } from "@coveo/levelup-protocol";
import { GitCalls } from "@coveo/levelup-protocol";
import md5 from "md5";
import { SearchAnalyticsClient } from "./search-analytics-client";

Vue.config.productionTip = false;

export interface Context {
  authenticated: boolean;
  testOrgsEnabled: boolean;
  config: RestInitConfig;
  user: RestUserDetails | null;
  amplitude: AmplitudeClient | null;
  searchAnalytics: SearchAnalyticsClient | null;
  disableOneTrust: boolean;
}

export let context: Context;
export let calls: RestCalls;
export let authoringCalls: AuthoringCalls;
export let gitCalls: GitCalls;

async function initializeContext(config: RestInitConfig, disableOneTrust: boolean): Promise<void> {
  let amplitudeClient: AmplitudeClient | null;
  if (!disableOneTrust && oneTrust.performanceCookiesEnabled() && config.amplitude?.apiKey) {
    try {
      amplitudeClient = amplitude.getInstance();
      let userId = "";
      if (config.user?.coveoEmployee) {
        userId = md5(config.user.email);
      }
      amplitudeClient.init(config.amplitude.apiKey, userId, {
        apiEndpoint: `analytics.cloud.coveo.com/amplitude`,
        forceHttps: true,
      });
    } catch (err) {
      amplitudeClient = null;
    }
  } else {
    amplitudeClient = null;
  }

  let searchAnalyticsClient: SearchAnalyticsClient | null;

  if (config.search != null) {
    searchAnalyticsClient = new SearchAnalyticsClient(config);
  } else {
    searchAnalyticsClient = null;
  }

  context = {
    authenticated: config.user != null,
    testOrgsEnabled: config.user != null && config.testOrgs != null,
    config: config,
    user: config.user,
    amplitude: amplitudeClient,
    searchAnalytics: searchAnalyticsClient,
    disableOneTrust: disableOneTrust,
  };

  if (config.user != null) {
    new EventWatcher().watchUser(async () => {
      context.user = await calls.getCurrentUser();
    });
  }
}

function instrumentAxiosInstance(axios: AxiosInstance): void {
  axios.interceptors.response.use(
    (res) => res,
    async (err) => {
      if (err.response != null && err.response.status === 401) {
        if (!context.authenticated) {
          if (await toasts.acknowledge("This operation requires you to be authenticated.")) {
            navigation.redirectToLogin();
          } else {
            window.location.reload();
          }
        } else {
          navigation.redirectToLogin();
        }
      } else if (err.response != null && err.response.status === 404) {
        navigation.redirectToNotFound();
      } else {
        return Promise.reject(err);
      }
    },
  );
}

export function injectOneTrust(config: RestInitConfig): void {
  const otScript = document.createElement("script");
  otScript.async = true;
  otScript.type = "text/javascript";
  otScript.dataset.domainScript = `189eb9e6-31f9-412d-981f-f16eb5581a81${config.production ? "" : "-test"}`;
  otScript.src = "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js";
  document.head.appendChild(otScript);
}

export async function initializeApplication(options: { disableOneTrust?: boolean } = {}): Promise<void> {
  const baseElement = document.querySelector("head base");
  const baseUrl = baseElement != null ? baseElement.getAttribute("href") : "";
  assert(baseUrl != null);

  calls = new RestCalls(window.location.host, window.location.protocol, baseUrl);
  instrumentAxiosInstance(calls.axios);
  authoringCalls = new AuthoringCalls(baseUrl);
  instrumentAxiosInstance(authoringCalls.axios);
  gitCalls = new GitCalls(baseUrl);
  instrumentAxiosInstance(gitCalls.axios);

  const initConfig = await calls.getInitConfig();

  if (!options.disableOneTrust) {
    injectOneTrust(initConfig);
    // Wait for OneTrust before initializing context
    await oneTrust.fetchOneTrustInstance();
  }

  await initializeContext(initConfig, !!options.disableOneTrust);
  await initializeHeadlessEngine();
  await amplitudeAnalytics.logIdentify();
  amplitudeAnalytics.logAmplitudeEvent("loaded application");
}
